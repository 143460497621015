let getFFT;
let endFrame;
let _code = '';
let _setupCode = '';
let scriptVariables = {};
let _lastWorkingCode = '';
export let _frame = 0;
let _running = true;
export let _images = [];
export let _videos = [];
let _lastError = '';
export let fadeInTime;
let fadeOutTime;
let renderStartTime;
let renderLength;
let smoothing = 0.8;
let eventTarget = new EventTarget();
export let maxCanvasSize = null;
const videoSources = [];

function _getFile(arr, idx) {
  if (idx>=arr.length) {
    return null;
  }
  return arr[idx];
}
export function _getImage(idx) {
  return _getFile(_images, idx);
}

export function _getVideo(idx) {
  return _getFile(_videos, idx);
}

function _logError(e, pane) {
  if (e.toString()===_lastError) {
    return;
  }
  _lastError = e.toString();
  console.log(`Error while running ${pane} script`, _lastError);
}

export async function loadFile({file, fileType}) {
  if (fileType==='image') {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = await new Promise(resolve => {
      reader.onload = () => {
        loadImage(reader.result, _file => {
          _images.push(_file);
        });
        resolve();
      }
    });
    return promise;
  }
  if (fileType==='video') {
    
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = await new Promise(resolve => {
      reader.onload = () => {
        let vid;
        vid = createVideo(reader.result, () => {
          vid.loop();
          vid.volume(0);
          vid.hide();
        });
        _videos.push(vid);
        resolve();
      };
    });
    return promise;
  }
}
const _tryRemove = () => {
  if (window.remove) {
    window.remove();
  }
};
async function resetVideos() {
  _videos = [];
  for (let i=0; i<videoSources.length; i++) {
    await loadFile(videoSources[i]);
  }
}

export default function Main() {
  fadeInTime = 1;
  fadeOutTime = 1;
  renderStartTime = 0;
  renderLength = 20;
  if (window.createjs) {
    window.Tween = createjs.Tween;
    window.Ease = createjs.Ease;
  }
  const setCallbacks = (_startFrame, _endFrame) => {
    getFFT = _startFrame;
    endFrame = _endFrame;
  };
  window.fftAverage = (fft, startBin, endBin) => {
    let count = 0;
    let total = 0;
    if (startBin===undefined) {
      startBin = 0;
    }
    if (endBin===undefined) {
      endBin = fft.length-1;
    }
    for (let i=startBin; i<=endBin; i++) {
      total += fft[i];
      count++;
    }
    return total/count;
  };
  window.setup = _ => {
    try {
      let canvasWidth = 254;
      let canvasHeight = 450;
      const _fadeInTime = fadeInTime;
      const _fadeOutTime = fadeOutTime;
      const _renderStartTime = renderStartTime;
      const _renderLength = renderLength;
      const _smoothing = smoothing;
      eval(_setupCode);
      fadeInTime = validateRenderParam(fadeInTime, _fadeInTime);
      fadeOutTime = validateRenderParam(fadeOutTime, _fadeOutTime);
      renderStartTime = validateRenderParam(renderStartTime, _renderStartTime, 0);
      renderLength = validateRenderParam(renderLength, _renderLength, 1);
      smoothing = validateRenderParam(smoothing, _smoothing, 0, 1);
      ({ canvasWidth, canvasHeight } = limitSizeWithAspectRatio(canvasWidth, canvasHeight));
      let canvas = createCanvas(canvasWidth, canvasHeight);
      canvas.parent('script-container');
      }
    catch(e) {
      _tryRemove();
      _logError(e, 'setup');
    }
    
    eventTarget.dispatchEvent(new CustomEvent('setupDone'));
  };

  function limitSizeWithAspectRatio(canvasWidth, canvasHeight) {
    if (!maxCanvasSize) {
      return { canvasWidth, canvasHeight };
    }
    const apsectRatio = canvasWidth / canvasHeight;
    if (canvasWidth > maxCanvasSize.x && canvasWidth > canvasHeight) {
      return {canvasWidth: maxCanvasSize.x, canvasHeight: Math.floor(maxCanvasSize.x / apsectRatio)}
    }
    if (canvasHeight > maxCanvasSize.y) {
      return {canvasWidth: Math.floor(maxCanvasSize.y * apsectRatio), canvasHeight: maxCanvasSize.y};
    }
    return { canvasWidth, canvasHeight };
  }

  function validateRenderParam(value, fallback, min, max) {
    if (typeof(value)!=='number') {
      return fallback;
    }
    if (min!==undefined && value<min) {
      return fallback;
    }
    if (max!==undefined && value>max) {
      return fallback;
    }
    return value;

  }

  function isIncomingAudioValid(audio) {
    return audio && audio.length>0 && audio[0]!==null;
  }

  function dummyExecution(audio) {
    try {
      if (!isIncomingAudioValid(audio)) {
        audio = [{
          fft: [],
          amplitude: 0
        }];
      }
      const fft = audio[0].fft;
      const amplitude = audio[0].amplitude;
      const frame = _frame;
      const images = _images;
      const videos = _videos;
      const getImage = _getImage;
      const getVideo = _getVideo;
      const _variables = scriptVariables;
      push();
      eval(_code);
      pop();
    } catch(e) {
      _logError(e, 'draw');
      endFrame(false);
      return;
    }
    endFrame(true);
  }

  window.draw = _ => {
    let _audio = getFFT();
    if (!isIncomingAudioValid(_audio)) {
      dummyExecution(_audio);
      background(0);
      return;
    }
    if (!_running) {
      background(0);
      return;
    }

    try {
      let audio = _audio;
      if (!audio) {
        audio = [{
          fft: [],
          amplitude: 0
        }];
      }
      const fft = audio[0].fft;
      const amplitude = audio[0].amplitude;
      const frame = _frame;
      const images = _images;
      const videos = _videos;
      const getImage = _getImage;
      const getVideo = _getVideo;
      const _variables = scriptVariables;
      push();
      eval(_code);
      pop();
      _lastWorkingCode = _code;
    } catch(e) {
      _logError(e, 'draw');
      endFrame(false);
      return;
    }
    endFrame(true);
  }
  window.p5.disableFriendlyErrors = true;
  const resetCode = async () => {
    scriptVariables = {};
    _lastWorkingCode = '';
    _tryRemove();
    new window.p5();
    await resetVideos();
    _frame  = 0;
  };
  const applyCode = async (newCode, pane) => {
    if (pane==='setup') {
      _setupCode = newCode;
      await resetCode();
    } else {
      _code = newCode;  
    }
    
  }

  const addFiles = async (files) => {
    while (files.length>0) {
      const file = files.pop();
      if (file.fileType==='video') {
        videoSources.push(file);
      }
      await loadFile(file);
    }
  }

  const setExecution = (running) => {
    _running = running;
    if (!running) {
      _videos
      .map(video=>video.elt)
      .forEach(video=> {
          video.pause();
      });
    }
  }

  const resetVideoFrames = () => {
    _videos
    .map(video=>video.elt)
    .forEach(video=> {
      if (video.paused == false) {
        video.pause();
      }
      video.currentTime = 0;
    });
  }

  const seekVideoFrame = (frame) => {
    _videos
    .map(video=>video.elt)
    .forEach(video=> {
      
      _setVideoFrame(video, frame);
    });
  };

  const setFrame = async (frame, rendering) => {
    _videos
    .map(video=>video.elt)
    .forEach(async video=> {
      if (rendering) {
        _setRenderVideoFrame(video, frame);
      } else {
        await _setLiveVideoFrame(video, frame);
      }

    });
    _frame = frame;
  };

  const _setRenderVideoFrame = (video, frame) => {
    if (!video.paused) {
      video.pause();
    }
    _setVideoFrame(video, frame);
  };

  const _setLiveVideoFrame = async (video, frame) => {
    if (video.paused) {
      _setVideoFrame(video, frame);
      await video.play();
    }
  };

  const _setVideoFrame = (video, frame) => {
    const durationInFrames = video.duration*30;
    while (frame>durationInFrames) {
      frame = frame - durationInFrames;
    }
    video.currentTime = frame / 30;
  };

  const getRenderParams = () => {
    return {
      fadeInTime,
      fadeOutTime,
      smoothing,
      renderStartTime,
      renderLength
    };
  };

  const setMaxCanvasSize = val => {
    maxCanvasSize = val;
  };


  
  return {
    applyCode,
    resetCode,
    setExecution,
    setFrame,
    addFiles,
    resetVideoFrames,
    seekVideoFrame,
    getRenderParams,
    eventTarget,
    setCallbacks,
    setMaxCanvasSize
  };
}