import {FRAME_RATE, SAMPLE_RATE, FFT_SIZE} from '../live/LivePlayer.vue';
export default class OfflineAnalyzer {
    fft = [];
    rms = [];
    offlineContext = null;
    analyzer = {};
    duration = 0;
    averaging = 0.95;
    buffer = null;
    constructor(buffer) {
        this.buffer = buffer;
    }

    getAnalysisForFrame(frame) {
        if (frame>this.fft.length-1) {
            return null;
        }
        return {fft: this.fft[frame], amplitude: this.rms[frame]};
    }

    async loadAudioFFT(smoothing) {
        this.fft = [];
        this.rms = [];
        const buffer = this.buffer.slice();
        this.offlineContext = new OfflineAudioContext(1, buffer.byteLength, SAMPLE_RATE);
        const source = this.offlineContext.createBufferSource();

        const promise = await new Promise(resolve => {
            const audioContext = new AudioContext();
            audioContext.decodeAudioData(buffer, (decoded) => {
                source.buffer = decoded;
                this.duration = source.buffer.duration;
                this.analyzer = this._connectOfflineAudioAnalyzer(source);
                this.analyzer.smoothingTimeConstant = smoothing;
                this.analyzer.fftSize = FFT_SIZE;
                source.connect(this.offlineContext.destination);
                this._captureFFTPerFrame(resolve);
                source.start();
                this.offlineContext.startRendering();
            });
        });
        return promise;
    }

    _captureFFTPerFrame(resolve) {
        const frames = Math.floor((this.duration)*FRAME_RATE);
        for (let i=0; i<frames; i++) {
            this.offlineContext.suspend((1/FRAME_RATE)*i).then(() => {
                this._captureFrameFFT();
                this._captureFrameRMS();
                this.offlineContext.resume();
                if (i===frames-1) {
                    this._finalizeAnalysis(resolve);
                }
            }).catch(e=> {
                this._finalizeAnalysis(resolve);
            });
        }
    }
    _captureFrameFFT() {
        const data = new Uint8Array(this.analyzer.frequencyBinCount);
        this.analyzer.getByteFrequencyData(data);
        this.fft.push(data);
    }
    _captureFrameRMS() {
        const data = new Float32Array(this.analyzer.fftSize);
        this.analyzer.getFloatTimeDomainData(data);
        let sample;
        let sum = 0;
        let volume = 0;
        for (let i=0; i<data.length; i++) {
            sample = data[i];
            sum += sample*sample;
        }
        const rms = Math.sqrt(sum/data.length);
        volume = Math.max(rms, volume*this.averaging);
        this.rms.push(volume);
    }
    _connectOfflineAudioAnalyzer(source) {

        const analyzer = this.offlineContext.createAnalyser();

        source.connect(analyzer);
        return analyzer;
    }

    _finalizeAnalysis(resolve) {
        resolve();
    }
}