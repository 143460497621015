<script setup>
  import mixpanel from 'mixpanel-browser';
  import { inject } from 'vue'
  window.AudioContext = window.AudioContext || window.webkitAudioContext;
  window.OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext; 
  const mixPanelKey = import.meta.env.VITE_MIXPANEL_KEY;
  let debug = false;
  if (window.Cypress || !mixPanelKey) {
    debug = true;
    mixpanel.init('0dd57fa7c3f36313758a5ae6f5c48dde', {debug});
  } else {
    mixpanel.init(mixPanelKey, {debug}); 
  }
  mixpanel.track('Access');
  
  const browserNotSupported = inject('browserNotSupported');

</script>

<template>
  <header>
    <nav>
      <div class="left-nav"></div>
      <router-link to="/">
        <div style="inline">
        <img src="/logo.png" style="display: inline;height: 35px;margin-top: 17px;"/>
        <img src="/logo_text.png" style="display: inline;height: 35px;margin-top: 17px;" />
      </div>
      </router-link>   
      <div class="right-nav">
        <router-link to="/about">About</router-link>
        <a href="mailto:contact@muzefuze.com">Contact</a>
        <a href="https://patreon.com/MuzeFuze"><img class="inline w-6 px-1" src="/external/Digital-Patreon-Logo_FieryCoral.png">Patreon</a>
      </div>
    </nav>
  </header>
  <main>
    <router-view />
  </main>
  <footer>
  </footer>
  <Modal v-model="browserNotSupported">
    <div class="modal">
      <h2>Browser not supported!</h2>
      <p>For the best experience, please use Chrome</p>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>

  header {
    height: $header-height;
    text-align: center;
    border-bottom: 1px #fff solid;
    position: relative;
    width: 100vw;
    
  }
  nav {
    position: absolute;
    line-height: $header-height;
    left: 1rem;
    width: 90vw;
    display: flex;
    justify-content: space-between;
    .right-nav a {
      margin-right: 3svw;
    }
    .left-nav {
      display:none;
      width: 178px;
      @include xl {
        display:block;
        
      }
    }
  }
  h1 {
    line-height: $header-height;
    margin: 0;
    font-size: 5vmin;
    color: #fff;
    user-select: none;
    display: inline;
  }
  .alpha {
    font-size: 2vmin;
    display: inline;
    font-style: italic;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    top: 24px;
  }
  .modal {
    width: 400px;
    padding: 30px;
    box-sizing: border-box;
    font-size: 20px;
    margin-top:20px;
    text-align: center;
  }
</style>
