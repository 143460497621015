<script>
  import CodePane from './CodePane.vue';
  import mixpanel from 'mixpanel-browser';
  import Persistence from "./Persistence.js";
  import {inject} from "vue";

  const supportedExtensions = {
    'image': ['png', 'jpg', 'jpeg', 'gif' ],
    'video': ['mp4', 'webm', 'ogg', 'mkv'],
    'audio': ['wav', 'mp3']
  };

  export default {
    components: {
      CodePane
    },
    emits: ['drawUpdate', 'setupUpdate', 'filesAdded', 'audioAdded', 'progressTutorial', 'toggleScriptExecution'],
    props: ['tutorialPhaseSelectCode', 'tutorialPhaseAddImage', 'scriptRunning'],

    computed: {
      dropSuccess() {
        return this.showDropSuccess ? 'drop-success' : '';
      },
      dropActive() {
        return this.showDragOverlay ? 'drop-active' : '';
      },
      templateModeClass() {
        return this.templateMode ? 'template-mode' : '';
      },
      hintText() {
        let text = '';
        if (!this.scriptRunning) {
          text = `<b style="color:#ED0800;">Code execution is paused!</b> When you enter code with loops, the code will pause to prevent infinite loops.\nWhen you're ready, click <b>here</b> or <b>⏵︎</b>  to unpause.`;
        } else
        if (this.templateMode) {
          text = 'The dotted border indicates you are in Template Mode.\nClick here to load an image as a background for your video.';
        } else {
          text = 'You are now in Edit Mode. If you load an image, you need to set it as background yourself.\nExample: image(images[0], 0, 0, width, height);'
        }

        return `<div>${text}</div>`
      },
      hintsAllowImageLoading() {
        return !this.scriptRunning;
      },
      code() {
        return this.paneData[this.activePane].code;
      },
      savePresetTooltip() {
        if (this.templateMode) {
          return 'Saving preset disabled in template mode';
        }
        return '';
      }
    },
    setup() {
      const  isMobile = () => document.documentElement.clientWidth<= 760;
      const betaFlag = inject('betaFlag');
      return {isMobile, betaFlag};
    },
    mounted() {
      document.addEventListener('dragenter', this.onDragEnter);
      document.addEventListener('dragover', this.onDragEnter);
      document.addEventListener('dragend', this.onDragEnd);
      document.addEventListener('dragleave', this.onDragEnd);
      document.addEventListener('drop', this.onDragEnd);
      this.presets = this.persistence.getArray('presets') || [];
      this.$emit(this.paneData[1].event, this.paneData[1].code, true);
    },
    unmounted() {
      document.removeEventListener('dragenter', this.onDragEnter);
      document.removeEventListener('dragover', this.onDragEnter);
      document.removeEventListener('dragend', this.onDragEnd);
      document.removeEventListener('dragleave', this.onDragEnd);
      document.removeEventListener('drop', this.onDragEnd);
    },
    data() {
      return {
        paneData: [ {
            name: 'Draw',
            event: 'drawUpdate',
            code: '',
            placeholder: 'Write p5js/chroma.js code here.\nAvailable variables:\n\tfft<array>\n\tamplitude<number>\n\tframe<number>\n\nAdditional helper methods:\n\tfftAverage(fft<array>,startBin<optional number>,endBin<optional number>)\n\nDrag and drop images/videos here\n\tAccess them with getImage(number)\n\nDrag and drop additional audio files here\n\tAccess them with audio[x].fft\n\nSelect one of the examples above to get started!'
          }, {
            name: 'Setup',
            event: 'setupUpdate',
            code: `canvasWidth = 254;\ncanvasHeight = 450;\nframeRate(60);\nrenderStartTime = 0;\nrenderLength = 20;\nfadeInTime = 1;\nfadeOutTime = 1;\ncolorMode(HSB);\nangleMode(DEGREES);`,
            placeholder: 'Write p5js Setup code here.\n\nTo change video dimensions, set canvasWidth & canvasHeight.\n\nExample:\ncanvasWidth=500;\ncanvasHeight=500;'
        }],
        activePane: 0,
        sendCodeTrackingEvent: true,
        fileCount: {image:0, video:0},
        templateMode: true,
        showDropSuccess: false,
        showDragOverlay: false,
        ignoreNextCodeChange: false,
        showSavePresetModal: false,
        persistence: new Persistence(),
        presetName: '',
        presets: [],
        mounted: false
      }
    },
    methods: {
      onDragEnter(e) {
        e.preventDefault() 
        e.stopPropagation();
        this.showDragOverlay = true;
      },
      onDragEnd(e) {
        e.preventDefault() 
        e.stopPropagation();
        this.showDragOverlay = false;
      },
      codeChanged(code) {
        if (this.ignoreNextCodeChange) {
          this.ignoreNextCodeChange = false;
          return;
        }
        if (this.sendCodeTrackingEvent) {
          mixpanel.track('Code Changed');
          this.sendCodeTrackingEvent = false;
        }
        this.templateMode = false;
        this.setCode(code, false);
      },
      setCode(code, safe) {
        this._setCode(code);
        this.$emit(this.paneData[this.activePane].event, code, safe);
      },
      onImageLoaded(event) {
        this._processFiles(event.target.files);
      },
      onDrop(ev) {
        const files = [];
        [...ev.dataTransfer.items].forEach((item, i) => {
          if (item.kind !== 'file') {
            return;
          }
          const file = item.getAsFile();
          files.push(file);
        });
        this._processFiles(files);
      },

      _processFiles(files) {
        if (files.length>0) {
          if (this.tutorialPhaseAddImage) {
            this._progressTutorial();
          }
          const filesToAdd = this.addFiles(files);
          if (filesToAdd.length>0) {
            setTimeout(() => {
              this.showDropSuccess = false;
            }, 1000);
            this.showDropSuccess = true;
            mixpanel.track('File added');
            this.$emit('filesAdded', filesToAdd);
          }
        }
      },
      
      addFiles(files) {
        const filesAdded = [];
        for (let i=0; i<files.length; i++) {
          const file = files[i];
          const parts = file.name.split(".");
          const extension = parts[parts.length-1].toLowerCase();
          let fileType;
          Object.keys(supportedExtensions).forEach(ext => {
            if (supportedExtensions[ext].includes(extension)) {
              fileType = ext;
            }
          });
          if (fileType) {
            if (fileType==='audio') {
              this.$emit('audioAdded', file);
            }
            filesAdded.push({file, fileType})
            if (this.templateMode) {
              const addCode = this._getFileTemplate(fileType, this.fileCount[fileType]);
              if (addCode) {
                const code = this._replaceBackgroundCode(addCode, this.code);
                this.setCode(code);
              }
            }
            this.fileCount[fileType]++;
          }
        }
        return filesAdded;
      },

      _getFileTemplate(extension, index) {
        if (extension==='image') {
          return `image(images[${index}], 0, 0, width, height);`;
        }
        if (extension==='video') {
          return `image(videos[${index}], 0, 0, width, height);`;
        }
        return null;
      },

      attemptStoredPresetLoad (presetName) {
        if (!this.presets) {
          return false;
        }
        const preset = this.presets.filter(preset => preset.name === presetName);
        if (!preset || preset.length===0) {
          return false;
        }
        this.selectSetupPane();
        this.setCode(preset[0].setup, true);
        this.selectDrawPane();
        this.setCode(preset[0].draw, true);
        mixpanel.track(`Preset Loaded: ${presetName}`);
        return true;
      },
      async loadTemplate (presetName) {
        this.activePane = 0;
        mixpanel.track(`Template Selected: ${presetName}`);
        const filePath = `/examples/${presetName}.js`;
        const response = await fetch(filePath);
        let code = await response.text();
        if (this.templateMode && this._codeIsImageLoad()) {
          code = this._replaceBackgroundCode(this.code, code);
        }
        this.templateMode = true;
        this.setCode(code, true);
      },
      async onPresetSelected(e) {
        this.ignoreNextCodeChange = true;
        const presetName = e.target.value;
        if (this.tutorialPhaseSelectCode) {
          this._progressTutorial();
        }
        if (this.attemptStoredPresetLoad(presetName)) {
          return;
        }
        await this.loadTemplate(presetName);
      },
      _codeIsImageLoad() {
        if (!this.code) {
          return false;
        }
        const lines = this.code.split('\n');
        return lines[0].indexOf('image(')===0;
      },
      _replaceBackgroundCode(replacementLines, originalLines) {
        if (!originalLines) {
          return replacementLines;
        }
        const templateLines = originalLines.split('\n');
        const newLines = replacementLines.split('\n');
        let newCode = [];
        let replaced = false;
        for (let i=0; i<templateLines.length; i++) {
          if (!replaced && templateLines[i].startsWith("background")) {
            newCode.push(newLines[0]);
            replaced = true;
          } else {
            newCode.push(templateLines[i]);
          }
        }
        return newCode.join('\n');
      },
      _progressTutorial() {
        this.$emit('progressTutorial');
      },
      onHintsClicked(e) {
        if (!this.scriptRunning) {
          e.preventDefault();
          this.$emit('toggleScriptExecution');
        }
      },
      _setCode(code) {
        this.paneData[this.activePane].code = code;
      },

      selectSetupPane() {
        this.activePane = 1;
      },

      selectDrawPane() {
        this.activePane = 0;
      },

      onSavePresetClicked() {
        this.showSavePresetModal = true;
      },
      overwriteExistingPreset(preset) {
        const existingCopyIndex = this.presets.findIndex(preset => preset.name === this.presetName);
        if (existingCopyIndex >= 0) {
          this.persistence.updateArrayElement('presets', existingCopyIndex, preset);
          return true;
        }
        return false;
      },
      saveNewPreset(preset) {
        this.presets.push(preset);
        this.persistence.addToArray('presets', preset);
      },
      onSaveClicked() {
        this.showSavePresetModal = false;
        if (!this.presetName || this.presetName.trim()==='') {
          return;
        }
        const preset = {
          version: 1,
          name: this.presetName,
          draw: this.paneData[0].code,
          setup: this.paneData[1].code
        };
        if (this.overwriteExistingPreset(preset)) {
          return;
        }
        this.saveNewPreset(preset);
      },

      onCancelSaveClicked() {
        this.showSavePresetModal = false;
        this.presetName = '';
      }

    }


    
  }
</script>
<style lang="scss">
  .code-container {
    box-shadow: 0 0 10px 10px rgba(50,255,50,0);
    transition: box-shadow 0.5s ease-in-out;
    border: $border-thickness solid $border-color;
    border-radius: 0px 20px 0px 0px;
    position: relative;
  }
  .drag-overlay {
    border-radius: 0px 20px 0px 0px;
    height: 400px;
    text-align: left;
    width: 50vw;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: white;
    pointer-events: none;
    &.drop-active {
      opacity: 0.2;
    }
    img {
      width: 200px;
      height:200px;
      margin: 100px auto;
      display: block;
    }
  }

  .cm-editor {
    border-radius: 0px 20px 0px 0px; 
    height: 400px;
    text-align:  left;
    width: 50vw;
  }
  .drop-success {
    box-shadow: 0 0 10px 10px rgba(50,255,50,1);
  }
  .right-controls {
    position: absolute;
    right: 0;
    
  }

  .template-mode{
    border: $border-thickness dashed $border-color;
  }
  .hints {
    text-align: left;
    padding-left: 1vw;
    font-size: 0.8em;
    line-height: 2em;
    border-top: 1px solid black;
    background-color: #282c34;
    color: #abb2bf;
    user-select: none;
    td:first-child {
      color: white;
    }
    td {
      max-width: 47vw;
      overflow: hidden;
    }
  }
  .pane-selector {
    text-align: left;
    cursor: pointer;
    display: inline;
    border-left: $border-thickness solid;
    border-right: $border-thickness solid;
    border-top: $border-thickness solid;
    border-color: $border-color;
    display: inline-block;
    background-color: #282c34;
    margin-left: 0;
    margin-right: 1vw;
    padding: 5px;

    // makes sure this appears above the canvas
    position: relative;
    z-index: 1;
    &.active {
      background-color: #002C34;
    }
  }
  .button-save-preset {
    float: right;
    margin-top: 5px;
  }

</style>
<template>
  <div>
    <div class="right-controls">
      <Popper class="popper-dark" arrow content="Select one of the code templates" :show="tutorialPhaseSelectCode">
        <select @change="onPresetSelected" value="">
          <option disabled value="">Presets</option>
          <option value="spectrum_reflection">Spectrum Reflection</option>
          <option value="particles">Particles</option>
          <option value="radial_cave">Radial cave</option>
          <option value="radial_s">Radial spectrum</option>
          <option value="rainbow_s">Raindow spectrum</option>
          <option
              v-for="(item, index) in presets"
              :value="item.name"
              >
            {{ item.name }}
          </option>
        </select>
      </Popper>
    </div>
    <div v-if="!isMobile()">
        <div class="setup-selector pane-selector" :class="[this.activePane===1 ? 'active': '']" @click="selectSetupPane">{{ this.paneData[1].name }}</div>
       <div class="draw-selector pane-selector" :class="[this.activePane===0 ? 'active': '']" @click="selectDrawPane">{{ this.paneData[0].name }}</div>
    </div>
    <Popper placement="left" class="popper-dark popper-content" arrow :show="tutorialPhaseAddImage" v-if="!isMobile()">
      <template #content>
        <p>
          Drag an image here to set it as background
        </p>
        <a @click="_progressTutorial">(skip)</a>
      </template>
      <div class="code-container"
           :class="[dropSuccess, templateModeClass]"
           @drop="onDrop"
           >
        <CodePane :data="paneData[activePane]" @codeChanged="codeChanged" />
        <div class="drag-overlay" :class="dropActive" @drop="onDrop">
              <img src="/icons/file-plus.svg" />
           </div>
        <div class="hints" @click="onHintsClicked">
          <table>
            <td>Hint:</td>
            <td style="white-space: pre;">
              <label for="imageSelector" v-html="hintText">
              </label>
            </td>
          </table>
          <input ref="imageSelector" id="imageSelector" type="file" accept="image/*" @input="onImageLoaded"/>
        </div>
      </div>
    </Popper>
    <button class="button-save-preset bg-green-300" @click="onSavePresetClicked" :disabled="this.templateMode" :title=savePresetTooltip>Save preset</button>
  </div>
  <Modal v-model="showSavePresetModal">
    <div class="modal text-center">
      <h2 class="text-xl">Select preset name</h2>
      <input class="box-border h-16 w-64 p-3 m-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg " placeholder="Your preset name" v-model="presetName"/>
      <div>
        <button class="m-1" @click="onSaveClicked">Save</button>
        <button class="m-1" @click="onCancelSaveClicked">Cancel</button>
      </div>
    </div>
  </Modal>
</template>