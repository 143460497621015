<script>
import { FRAME_RATE } from '../live/LivePlayer.vue';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import mixpanel from 'mixpanel-browser';
import OfflineAnalyzer from './OfflineAnalyzer.js';
import { inject } from 'vue';

const ffmpegLogging = false;
export const captureVideoMessage = 'Capturing video (1/2)';
export const convertVideoMessage = 'Converting video to mp4 (2/2)';

export default {
	emits: ['progressTutorial', 'renderStart', 'renderDone', 'renderFrame'],
	props: ['script', 'tutorialPhaseRender', 'maxRenderDuration', 'overlayRender'],
	data() {
		return {
			offlineAnalyzers: [],
			capturer: null,
			ffmpeg: null,
			capturing: false,
			converting: false,
			audioArrayBuffer: null,
			file: null,
			renderedFrame: 0,
      codeExecutedSuccessfully: true,
			partialRenders: [],
			showRenderModal: false,
			renderMessage: ''
		}
	},
	computed: {
		buttonText() {
			if (this.capturing) {
				return 'Rendering';
			}
			if (this.converting) {
				return 'Converting'
			}
			return 'Render';
		},
		renderTooltip() {
			if (this.disableRendering) {
				return 'Render disabled while code is invalid';
			}
			return '';
		},
		maximumRenderDuration() {
			return (this.maxRenderDuration || 9999);
		},
    disableRendering() {
      return !this.codeExecutedSuccessfully || this.converting;
    }
	},
	methods: {
		getAudioAnalysis() {
	      return this.offlineAnalyzers.map(a=>a.getAnalysisForFrame(this.renderedFrame));
		},

		async toggleRender() {
			if (this.tutorialPhaseRender) {
				this._progressTutorial();
			}
			if (this.capturing) {
				await this._finishRender();
				return;
			} 
			await this._startRender();
		},

		async fileChanged(file, isPrimary) {
			const promise = await new Promise(resolve => {
				const reader = new FileReader();
				reader.onloadend = async () => {
          let buffer = reader.result;
          if (file.type==='audio/mpeg') {
            buffer = await this.convertMP3(buffer);
          }
          if (isPrimary) {
            this.audioArrayBuffer = this._writeAudioToArrayBuffer(buffer);
            this.offlineAnalyzers = [];
          }
          const analyzer = new OfflineAnalyzer(buffer);
          this.offlineAnalyzers.push(analyzer);
          resolve(true);
				};
				reader.readAsArrayBuffer(file);
			});
			return promise;
		},

	    _writeAudioToArrayBuffer(buffer) {
	      const arrayBuffer = new ArrayBuffer(buffer.byteLength);
	      new Uint8Array(arrayBuffer).set(new Uint8Array(buffer));
	      return arrayBuffer;
	    },

		async convertMP3(buffer) {
			if (!this.ffmpeg) {
				this.ffmpeg = createFFmpeg({ log: ffmpegLogging });
				await this.ffmpeg.load();
			}
			this.ffmpeg.FS("writeFile", `audio.mp3`, new Uint8Array(buffer));
			const args = ['-i', 'audio.mp3', 'audio.wav'];
			await this.ffmpeg.run(...args);
			return this.ffmpeg.FS("readFile", "audio.wav").buffer;
		},
			
		async _startRender() {
			this.renderMessage = captureVideoMessage;
     	 	this.showRenderModal = true;
			const renderParams = this.script.getRenderParams();
			await Promise.all(this.offlineAnalyzers.map(async (a) => {
				await a.loadAudioFFT(renderParams.smoothing, renderParams.fftSize);
			}));
			mixpanel.track('Render start');
			this.$emit('renderStart');
    		
			if (renderParams.renderStartTime>this.offlineAnalyzers[0].duration) {
				renderParams.renderStartTime = 0;
			}
			this.renderedFrame = Math.floor(renderParams.renderStartTime*FRAME_RATE);
			this.script.resetCode();
			this.script.resetVideoFrames();
			this.script.setExecution(true);
			this.partialRenders = [];
			const format = 'png';
			const autoSaveCallback = this.partialRenderCallback;
			if (this.offlineAnalyzers[0].duration>50) {
				this.capturer = new CCapture({format, framerate: FRAME_RATE, verbose: false, autoSaveTime: 30, autoSaveCallback});
			} else {
				this.capturer = new CCapture({format, framerate: FRAME_RATE, verbose: false}); 
			}
			this.capturing = true;
			this.capturer.start();
			this.renderFrame(true);
		},
		async _finishRender() {
			this._stopRender();
			const converter =  this._convertVideo;
			this.capturer.save(converter);
		},
		_stopRender() {
			this.capturer.stop();
			this.capturing = false;
			this.script.setExecution(false);
		},
		partialRenderCallback(videoBlob) {
			this.partialRenders.push(videoBlob.flat());
		},
		

		async _convertVideo(videoBlob) {
			this.converting = true;
      this.renderMessage = convertVideoMessage;
			if (!this.ffmpeg) {
				this.ffmpeg = createFFmpeg({ log: ffmpegLogging });
				await this.ffmpeg.load();
			}
			this.ffmpeg.setLogger(this.onConversionProgress);
			const frames = this.partialRenders.flat().concat(videoBlob);
			for (let i=0; i<frames.length; i++) {
				const buffer = await frames[i].arrayBuffer();
				const idx = String(i).padStart(7, '0')
				this.ffmpeg.FS("writeFile", `${idx}.png`, new Uint8Array(buffer));	
			}

			let interimVideoName = 'interim.mp4';

			const audioTrimArgs = [];
			const fullAudioFile = 'video.wav';
			this.ffmpeg.FS("writeFile", fullAudioFile, new Uint8Array(this.audioArrayBuffer));

			const renderParams = this.script.getRenderParams();
			audioTrimArgs.push('-i');
			audioTrimArgs.push(fullAudioFile);
			audioTrimArgs.push('-ss');
			audioTrimArgs.push(renderParams.renderStartTime.toString());
			audioTrimArgs.push('-t');
			const possibleRenderTimeLeft = this.offlineAnalyzers[0].duration - renderParams.renderStartTime;
			const videoDuration = Math.min(this.maximumRenderDuration, possibleRenderTimeLeft, renderParams.renderLength);
			audioTrimArgs.push(videoDuration.toString());
			audioTrimArgs.push('trimmed.wav');
			await this.ffmpeg.run(...audioTrimArgs);

			let args = [];
			args.push('-r');
			args.push('60');
			args.push('-f');
			args.push('image2'); //format
			args.push('-i');
			args.push('%07d.png');
			args.push('-i');
			args.push('trimmed.wav');
			args.push('-vcodec');
			args.push('libx264')
			args.push('-crf');
			args.push('17');
			args.push('-pix_fmt');
			args.push('yuv420p');
      const fadeOutStart = Math.max(0, videoDuration - renderParams.fadeOutTime);
      if (renderParams.fadeInTime>0 || renderParams.fadeOutTime>0) {
        function addFadeCommand(video) {
          const _args = [];
          const command = video ? '-vf' : '-af';
          _args.push(command);
          let fadeInCommand = '';
          let fadeOutCommand = '';
          if (renderParams.fadeInTime>0) {
            fadeInCommand = `fade=t=in:st=0:d=${renderParams.fadeInTime}`;
          }
          if (renderParams.fadeOutTime>0) {
            fadeOutCommand += `fade=t=out:st=${fadeOutStart}:d=${renderParams.fadeOutTime}`;
          }
          if (!video) {
            if (fadeInCommand) {
              fadeInCommand = 'a' + fadeInCommand;
            }
            if (fadeOutCommand) {
              fadeOutCommand = 'a' + fadeOutCommand;
            }
          }
          _args.push(`${fadeInCommand}${fadeInCommand?',':''}${fadeOutCommand}`);
          return _args;
        }
        args = args.concat(addFadeCommand(true));
        args = args.concat(addFadeCommand(false));
	    }
	    args.push('video.mp4');
	    await this.ffmpeg.run(...args);
	    const data = this.ffmpeg.FS("readFile", "video.mp4");
	    this.converting = false;
	    download(new Blob([data.buffer]), 'video.mp4', 'video/mp4');
	    mixpanel.track('Render done');
	    this.partialRenders = [];
	    this.$emit('renderDone');
      this.showRenderModal = false;

		},

		async renderFrame(codeExecutedSuccessfully) {
			eval(atob(this.overlayRender || ''));
			/*
			const ctx = document.getElementById('defaultCanvas0').getContext('2d')
			ctx.font = "11.8px Arial"
			ctx.fillStyle = 'rgb(128,128,128,0.5)'
			const cs = ctx.canvas.style
			const hgt = parseInt(cs.height)
			const wd = parseInt(cs.width)
			ctx.fillText("Made With", wd-65, hgt-19)
			ctx.font = "12px Arial"
			ctx.fillText("MuzeFuze", wd-65, hgt-9)
			*/

			if (this.capturing) {
				if (!codeExecutedSuccessfully) {
					await this._finishRender();
					return;
				}
				await this.script.setFrame(this.renderedFrame, true);
				this.$emit('renderFrame', this.renderedFrame);
				const frame = this.capturer.capture(document.getElementById('defaultCanvas0'));
				this.renderedFrame++
				const renderParams = this.script.getRenderParams();
        const renderDuration = Math.min(this.maximumRenderDuration, renderParams.renderLength);
				const maxAllowedFrame = (renderDuration+renderParams.renderStartTime)*FRAME_RATE;
				if (this.renderedFrame>=this.offlineAnalyzers[0].fft.length || this.renderedFrame>=maxAllowedFrame) {
					await this._finishRender();
				}

			}

		},
		isCapturing() {
			return this.capturing;
		},
		_progressTutorial() {
        	this.$emit('progressTutorial');
  	},
  	codeExecutionSuccess(success) {
  		this.codeExecutedSuccessfully = success;
  	},
  	onConversionProgress({type, message}) {
  		const regex = /^frame=\s+(\d+)/;
  		const frame = regex.exec(message);
  		if (!frame) {
  			return;
  		}
  		const renderParams = this.script.getRenderParams();
  		const renderedFrame = parseInt(frame[1], 10)+renderParams.renderStartTime*FRAME_RATE;
  		this.$emit('renderFrame', renderedFrame);
  	}
	}
}
</script>
<style lang="scss">
	.button-render {
	  &:disabled {
	  	opacity: 0.2;
	  	cursor: default;
	  }
	}
</style>
<template>
	<Popper class="popper-dark popper-content" arrow :show="tutorialPhaseRender">
		<template #content>
			<p>
				Click Render to get the music video! 
			</p>
			<p>
				Creating the video can take time if your audio file is long!
			</p>
			<a @click="_progressTutorial">(skip)</a>
		</template>
		<button :disabled=disableRendering class="button-render bg-sky-300" @click="toggleRender" :title=renderTooltip>{{buttonText}}</button>
	</Popper>
  <Modal v-model="showRenderModal">
    <div class="modal text-center">
      <h2>{{renderMessage}}</h2>
      <p> &nbsp;</p>
      <p><a href="https://patreon.com/MuzeFuze" target="_blank">Support us on <img class="inline w-6 px-1" src="/external/Digital-Patreon-Logo_FieryCoral.png" />!</a> </p>
    </div>
  </Modal>
	
</template>	