<script>
	import mixpanel from 'mixpanel-browser';
	export default {
		data() {
			return {
				premiumInterestClicked: false
			}
		},
		mounted() {
			mixpanel.track('About Page');
		},
		methods: {
			premiumInterestClick() {
				this.premiumInterestClicked = true;
				mixpanel.track('Premium interest');
			}
		}
	}
</script>
<template>
	<div class="page-container font-sans leading-normal tracking-normal">
		<p class="font-bold font-sans break-normal pt-6 pb-2 text-3xl md:text-4xl">
			MuzeFuze alpha
		</p>
		<p class="py-3">
			Create tailor made, reactive Music Videos in your browser or phone.
		</p>
		<p class="py-3">
			Support us on <a href="https://patreon.com/MuzeFuze?utm_medium=clipboard_copy&utm_source=copyLink&utm_campaign=creatorshare_creator&utm_content=join_link">&nbsp;&nbsp;&nbsp;<img class="inline" width="20px" src="/external/Digital-Patreon-Logo_FieryCoral.png">Patreon!</a>
		</p>
		<p class="py-3">
			MuzeFuze is free to use. Non-supporters have the following limitations:
			<ul class="list-disc">
				<li class="mx-8 py-2">Watermark is present</li>
				<li class="mx-8 ">Up to 20 seconds video</li>
				<li class="mx-8 py-2">Limited canvas size</li>
			</ul>
		</p>
		<p class="py-3">
			<p class="font-bold">Privacy</p>
			MuzeFuze works directly in your device. Your music, images, videos and code are not stored or sent anywhere. Your creations & assets cannot be accessed by anyone but you.
			<p>We collect anonymous analytics to track usage of our service. No identifying information is stored other than country and Browser/OS used. </p>
		</p>
		<p class="py-3">
			<p class="font-bold">Built with</p>
			<ul>
				<li>P5.js</li>
				<li>chroma.js</li>
				<li>CCapture</li>
				<li>FFMPEGWasm</li>
				<li>CodeMirror</li>
				<li>WaveSurfer</li>
			</ul>
		</p>
	</div>
</template>