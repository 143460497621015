<script>
	import { Codemirror } from 'vue-codemirror';
 	import { javascript } from '@codemirror/lang-javascript';
	import { oneDark } from '@codemirror/theme-one-dark';

	export default {
		emits: ['codeChanged'],
		props: ['data'],
    components: {
    		Codemirror,
		},
		data() {
			return {
			 	extensions: [javascript(), oneDark]
			}
		},
		methods: {
			codeChanged(code) {
				this.$emit('codeChanged', code);
			}
		}
	}
</script>
<style lang="scss">
</style>
<template>
	<codemirror
          v-model="data.code"
        :placeholder="data.placeholder"
          :autofocus="true"
          :indent-with-tab="true"
          :tab-size="2"
          :extensions="extensions"
          @change="codeChanged"
        />
</template>