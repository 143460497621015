export default class Persistence {
    constructor() {

    }

    set(key, value) {
        localStorage.setItem(key, value);
    }

    get(key) {
        return localStorage.getItem(key);
    }

    addToArray(key, value) {
        let obj = this.getArray(key);
        if (obj) {
            obj.push(value);
        } else {
            obj = [value];
        }
        this.set(key, JSON.stringify(obj));
    }

    updateArrayElement(key, index, value) {
        const ar = this.getArray(key);
        if ( ar===null || index<0 || index>=ar.length) {
            return;
        }
        ar[index] = value;
        this.set(key, JSON.stringify(ar));
    }

    getArray(key) {
        const objString = this.get(key);
        if (objString) {
            return JSON.parse(objString);
        } 
        return null;
    }
}