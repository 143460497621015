<script>

import Main from '../shells/p5js_shell.js';
import Renderer from './render/Renderer.vue';
import LivePlayer from './live/LivePlayer.vue';
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import CodeEditor from './CodeEditor.vue';
import { inject } from 'vue';
import Persistence from "./Persistence.js";


const AUDIO_NOT_LOADED = 0;
const AUDIO_LOADING = 1;
const AUDIO_LOADED = 2;

export default {
	components: {
		Renderer,
		CodeEditor,
		LivePlayer
	},
	setup() {
		const browserSupported = !inject('browserNotSupported');
		const isMobile = () => screen.width <= 760;
		const betaFlag = inject('betaFlag');
		let premiumSettings = import.meta.env.VITE_PREMIUM_SETTINGS;
		if (premiumSettings) {
			premiumSettings = atob(premiumSettings);
			premiumSettings = JSON.parse(premiumSettings);
		} else {
			premiumSettings = {};
		}
    return {  browserSupported, isMobile, betaFlag, premiumSettings };
  },
	mounted() {
		this.script.setCallbacks(this.getAudioAnalysis, this.endFrame);
		this.script.setMaxCanvasSize(this.premiumSettings.maxCanvasSize);
    this.tutorialDone = this.persistence.get('tutorialDone')==='true';
	},
	data() {
		return {
		  lastAnalysis: null,
		  file: null,
		  audioLoaded: AUDIO_NOT_LOADED,
		  tutorialPhase: 0,
		  script: Main(),
		  scriptRunning: true,
		  lastSafeCodes: {},
      persistence: new Persistence(),
    	tutorialDone: false
		}
	},
	computed: {
		playPauseText() {
			return this.scriptRunning ? '⏸\uFE0E': '⏵\uFE0E';
		},
		tutorialPhaseSelectCode() {
			return this.tutorialPhase===1;
		},
		tutorialPhasePreview() {
			return this.tutorialPhase===2;
		},
		tutorialPhaseAddImage() {
			return this.tutorialPhase===3;
		},
		tutorialPhaseRender() {
			return this.tutorialPhase===4;
		},
		tutorialPhaseDone() {
			return this.tutorialPhase>4;
		},
		showLoadAudioModal() {
			return this.browserSupported && this.isAudioNotLoaded;
		},
		showAudioLoadingModal() {
			return this.audioLoaded===AUDIO_LOADING;
		},
		isAudioNotLoaded () {
			return this.audioLoaded===AUDIO_NOT_LOADED;
		}


	},
	methods: {
		async primaryAudioFileChanged(event) {
			this.file = this._getSelectedFile();
			if (!this.validateAudioFile()) {
				return;
			}
			this.audioLoaded = AUDIO_LOADING;
			await this.$refs.livePlayer.fileChanged(this.file, 0);
			const fileLoaded = await this.$refs.renderer.fileChanged(this.file, true);
			if (!fileLoaded) {
				this.audioLoaded = AUDIO_NOT_LOADED;
				return;
			}
			this.audioLoaded = AUDIO_LOADED;
			if (!this.tutorialDone) {
				this.tutorialPhase = 1;
			}
			this.lastAnalysis = null;
			if (this.script) {
				this.script.resetCode();
			}
		},

		validateAudioFile() {
			if (!this.file) {
				return false;
			} 
			// accept empty mimetype to bypass a Cypress bug where loading the same fixture twice 
			// makes it have an empty mimetype
			// https://github.com/cypress-io/cypress/issues/26095
			if (this.file.type && !this.file.type.startsWith('audio/')) {
				return false;
			}
			return true;
		},

    async loadSecondaryAudioFile(file) {
			await this.$refs.livePlayer.fileChanged(file);
			await this.$refs.renderer.fileChanged(file, false);
		},

		_getSelectedFile() {
			const files = this.$refs.fileSelector.files;
			return files[0];
		},
		
		getAudioAnalysis() {
			if (!this.$refs.renderer) {
				return null;
			}
		 	if (this.$refs.renderer.isCapturing()) {
		 		return this.$refs.renderer.getAudioAnalysis();
			 } 
			const analysis = this.$refs.livePlayer.getAudioAnalysis();
			if (analysis.length===0) {
				return this.lastAnalysis;
			}
			this.lastAnalysis = analysis;
			return analysis;
			
		},
		endFrame(codeWasSuccessful) {
			if (!this.$refs.renderer) {
				return;
			}
			this.$refs.renderer.codeExecutionSuccess(codeWasSuccessful);
			this.$refs.renderer.renderFrame(codeWasSuccessful);
		},
		drawCodeUpdate(code, safe) {
			if (safe) {
				this.script.resetCode();
			}
			this.codeChanged(code, safe, 'draw');
		},
		setupCodeUpdate(code, safe) {
			this.codeChanged(code, safe, 'setup');
		},
		codeChanged(code, safe, pane) {
			if (!pane) {
				pane = 'draw';
			}
			if (!this.lastSafeCodes[pane]) {
				this.lastSafeCodes[pane] = '';
			}
			if (!this.script || Object.prototype.toString.call(code) !== "[object String]") {
				return;
			}			
			if (safe) {
				this.lastSafeCodes[pane] = code;
			}
			if (code === this.lastSafeCodes[pane]) {
				this.script.applyCode(code, pane);
				return;
			}
			if (this.containsNewUnsafeWord(code, pane, 'for') || this.containsNewUnsafeWord(code, pane, 'while') || this.containsNewUnsafeWord(code, pane, 'push')) {
				this.toggleScriptExecution();
			}
			this.script.applyCode(code, pane);
			this.lastSafeCodes[pane] = code;
		},
		filesAdded(files) {
			this.script.addFiles(files);
		},
		containsNewUnsafeWord(code, pane, word) {
			const regex = new RegExp(word, 'g');
			const newForOccurances = (code.match(regex) || []).length;
			const oldForOccurances = (this.lastSafeCodes[pane].match(regex) || []).length;
			if (newForOccurances>oldForOccurances && this.scriptRunning) {
				return true;
			}
			return false;
		},
		toggleScriptExecution() {
			this.scriptRunning  = !this.scriptRunning;
			this.script.setExecution(this.scriptRunning);
		},
		frameRendered(frame) {
      		this.$refs.livePlayer.setFrame(frame);
		},
		async LivePlayerFrameUpdate(frame) {
			await this.script.setFrame(frame);
		},
		progressTutorial() {
			if (this.tutorialDone) {
				return;
			}
			this.tutorialPhase++;
			if (this.tutorialPhaseAddImage & this.isMobile()) {
				this.tutorialPhase++;
			}
			if (this.tutorialPhaseDone) {
				this.persistence.set('tutorialDone', 'true');
			}
		},
		endTutorial() {
			this.tutorialPhase = 999;
			this.progressTutorial();
		},
		renderStarted() {
			this.endTutorial();
      this.$refs.livePlayer.audioPause();
    },
		toggleLiveAudio() {
			if (!this.audioLoaded) {
				return;
			}
	      	const isPlaying = this.$refs.livePlayer.toggleAudio();
	      	if (!this.scriptRunning) {
	      		return;
	      	}
	      	this.script.setExecution(isPlaying);
			if (this.tutorialPhasePreview) {
				this.progressTutorial();
			}		
		},

	}
}


</script>
<style lang="scss">
	.script-play-pause {
	  position: absolute;
	  padding: 0;
	  border-radius: 4px;
	  line-height: 12px;
	  top:-12px;
	}
	.script-play-pause:focus {
	  outline: none;
	  box-shadow: none;
	}
	.code {
		height: 0;
		@include xl {
	  	height: 450px;
	  }
	  width: 50vw;
	  position: relative;
	  top: -63px;
	  vertical-align: top;
	}

	.preview {
	  width: 93vw;
	  height: 450px;
	  vertical-align: top;
	  text-align: center;
	  position: relative;
	  @include xl {
	  	display: inline-block;
	  	width: 43vw;
	  }
	}

	.button-file-select label{
	  cursor: pointer;
	}

	.controls {
	  margin: 2em;
	  margin-bottom: 0;
	  margin-top: 40px;
	  display: flex;
	}

	.left-controls {
		width: 50vw;
		@include xl {
	  	width: 510px;
	  }
	  justify-content: space-between
	}
	.flex-container {
	  display: flex;
	  position:relative;
	}
	.page-container {
		padding: 1rem;
	  padding-bottom: 0;
	}
	.inline-block {
		display:  inline-block;
	}

	.preview-and-code-container {
		> div {
			margin-top:  20px;
		}
	}
	#waveFormOuterContainer {
		width: 93vw;
		@include xl {
	  	width: 43vw;
	  }
		

	}
	#waveFormContainer {
		width: 93vw;
		@include xl {
	  	width: 43vw;
	  }
		height: 40px;
	}

	.text-center {
		text-align: center;
	}

	.mobile-note {
		padding-top: 2vh;
		font-size: 3vw;
	}

</style>

<template>
	<div class="flex-container page-container">
		<div>
			<div class="left-controls flex-container">
				<button class="button-file-select">
					<label for="fileSelector" v-if="isMobile()">
						Audio
					</label>
					<label for="fileSelector" v-else>
						Select Audio
					</label>
				</button>
				<input ref="fileSelector" id="fileSelector" type="file" accept="audio/*" @input="primaryAudioFileChanged"/>
				<Popper class="popper-dark" arrow content="Play the audio to see the video" :show="tutorialPhasePreview">
					<button class="bg-teal-300" :disabled="isAudioNotLoaded" @click="toggleLiveAudio" >Preview</button>
				</Popper>
				<LivePlayer ref="livePlayer" @frameUpdate="LivePlayerFrameUpdate" :script="script"/>
				<Renderer :script="script" ref="renderer" @renderFrame="frameRendered"
				:tutorialPhaseRender="tutorialPhaseRender" @progressTutorial="progressTutorial"
				@renderStart="renderStarted" :maxRenderDuration="premiumSettings.maxRenderDuration" :overlayRender="premiumSettings.overlayRender"/>
			</div>
			<div class="inline-block preview-and-code-container">
				<div class="preview" id="script-container">
					<button class="script-play-pause" @click="toggleScriptExecution" v-if="!isMobile()">
						{{playPauseText}}
					</button>
				</div>
				
				<div class="code inline-block">
					<CodeEditor @drawUpdate="drawCodeUpdate" @setupUpdate="setupCodeUpdate" @filesAdded="filesAdded" @audioAdded="loadSecondaryAudioFile" @progressTutorial="progressTutorial" @toggleScriptExecution="toggleScriptExecution" :tutorialPhaseSelectCode="tutorialPhaseSelectCode" :tutorialPhaseAddImage="tutorialPhaseAddImage" :scriptRunning="scriptRunning"/>
				</div>
			</div>
			<div id="waveFormOuterContainer">
				<div id="waveFormContainer" />
			</div>
		</div>
	</div>
	<Modal v-model="showLoadAudioModal">
		<div class="modal text-center">
			<h2>Let's make a music video!</h2>
			<p>To start, select an audio file:</p>
			<button class="button-file-select">
					<label for="fileSelector">
						Select Audio
					</label>
			</button>
			<div v-if="isMobile()" class="mobile-note">
				(NOTE - Mobile version is very limited. Please try me on your Desktop!)
        <iframe style="margin: 0 auto" src="https://www.youtube-nocookie.com/embed/zyE0w_JxhgA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen credentialless></iframe>
      </div>

      <div v-else>
        <p class="pt-5 pb-2">
          Quick tutorial:
        </p>
        <div>
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/zyE0w_JxhgA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen credentialless></iframe>
        </div>
      </div>

		</div>
	</Modal>

	<Modal v-model="showAudioLoadingModal">
		<div class="modal text-center">
			<h2>Loading audio file...</h2>
		</div>
	</Modal>

</template>