

<script>
import {FFT_SIZE} from './LivePlayer.vue';

export default {
  name: "AudioWrapper.vue",
  emits: ['play', 'pause'],
 data() {
   return {
     player: null,
     dataArray: null,
     analyzer: null,
     audioContext: null,
     fileLoaded: false,
     rms: 0,
     volume: 1,
     smoothing: 0.8,
   }
 },
  mounted() {
    this.player = this.$refs.player;
  },
  methods: {
    fileChanged(file) {
      this.$refs.player.src = URL.createObjectURL(file);
      this.fileLoaded = true;
    },
    setVolume(volume) {
      this.volume = volume;
    },
    getPlayer() {
      return this.player;
    },
    play() {
      if (!this.fileLoaded) {
        return false;
      }
      if (!this.analyzer) {

        this._initializeAudioAnalyzer();
      }
      this.$refs.player.play();
      return true;
    },
    getCurrentTime() {
      return this.$refs.player.currentTime;
    },
    seekTo(time) {

      this.$refs.player.currentTime = time;
    },
    seekToPercentage(percentage) {
      percentage = percentage*100;
      const durationPct = this.$refs.player.duration/100;
      const targetTime = durationPct * percentage;
      this.$refs.player.currentTime = targetTime;
    },
    pause() {
      if (!this.fileLoaded) {
        return false;
      }
      this.$refs.player.pause();
      return true;
    },
    getAudioAnalysis() {
      if (!this.analyzer) {
        return null;
      }
      this.analyzer.getByteFrequencyData(this.dataArray);
      return {fft: this.dataArray, amplitude: this.rms};
    },
    reinitAudioAnalyzers(renderParams) {
      this.smoothing = renderParams.smoothing;
      this.setAnalyzerProperties();
    },
    setAnalyzerProperties() {
      if (this.analyzer) {
        this.analyzer.fftSize = FFT_SIZE;
        this.analyzer.smoothingTimeConstant = this.smoothing;
      }
    },
    async _initializeAudioAnalyzer() {
      this.audioContext = new AudioContext();
      const source = this.audioContext.createMediaElementSource(this.$refs.player);
      this.analyzer = this.audioContext.createAnalyser();
      const gainNode = this.audioContext.createGain();
      source.connect(this.analyzer);
      this.analyzer.connect(gainNode);
      gainNode.connect(this.audioContext.destination);
      gainNode.gain.value = this.volume;
      this.dataArray = new Uint8Array(this.analyzer.frequencyBinCount);
      let processor;
      try {
        const processor = new AudioWorkletNode(this.audioContext, 'rms');
      } catch(e) {
        await this.audioContext.audioWorklet.addModule('rms.js');
        processor = new AudioWorkletNode(this.audioContext, 'rms');
      }
      if (processor) {
        source.connect(processor);
        processor.port.onmessage = e => {
          this.rms = e.data.rms;
        };
      }
      this.setAnalyzerProperties();
    },
    onPlay() {
    this.$emit('play');
    },
    onPause() {
      this.$emit('pause');
    }
  },


}
</script>

<style scoped>

</style>

<template>
  <audio ref="player"
         @play="onPlay"
         @pause="onPause"
         loop="true"
  >
  </audio>
</template>