import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router.js';
import Popper from "vue3-popper";

import 'vue-universal-modal/dist/index.css';
import VueUniversalModal from 'vue-universal-modal';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: 'bbd7349c90514f6da02792bbd1a9f0af',
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ['production']
});



const app = createApp(App);


const userAgent = navigator.userAgent;
  let browserNotSupported = true;
  if(userAgent.match(/chrome|chromium|crios/i)){
    browserNotSupported = false;
  } else if(userAgent.match(/firefox|fxios/i)){
    browserNotSupported = true;
  } else if(userAgent.match(/safari/i)){
    browserNotSupported = true;
  } else if(userAgent.match(/opr\//i)){
    browserNotSupported = false;
  } else if(userAgent.match(/edg/i)){
    browserNotSupported = false;
  }

app.provide('browserNotSupported', browserNotSupported);
app.provide('betaFlag', new URLSearchParams(window.location.search).has('beta'));
app.provide('premium', false);
app.component("Popper", Popper);
app.use(VueUniversalModal, {
  teleportTarget: '#modals',
});

app.use(router);

app.use(Bugsnag.getPlugin('vue'));

app.mount('#app');

