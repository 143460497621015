import WaveSurfer from 'wavesurfer.js';

export default class WaveSurferWrapper {
	constructor(doSeek) {
		this.audioDisplay = null;
		this.seek = false;
		this.seekLocation = 0;
		this.seekCallback = null;
	}
	create(params) {
		if (this.audioDisplay) {
			return;
		}
		this.audioDisplay = WaveSurfer.create(params);
		this.audioDisplay.on('interaction', (a) => {
			if (this.seek && this.seekCallback) {
				this.seekCallback(this.seekLocation);
				this.seek = false;
			}
			
		});
		this.audioDisplay.drawer.on('click', (e, progress) => {
			this.seekLocation = progress;
			this.seek = true;
		});
	}
	onSeek(callback) {
		this.seekCallback = callback;
	}
	toggleInteraction() {
		this.audioDisplay.toggleInteraction();
	}
	loadBlob(file) {
		this.audioDisplay.loadBlob(file);
	}
	seekTo(location) {
		this.audioDisplay.seekTo(location);
	}
}