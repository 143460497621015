<script>
import WaveSurferWrapper from './WaveSurferWrapper.js';
import AudioWrapper from "./AudioWrapper.vue";

export const FRAME_RATE = 60;
export const SAMPLE_RATE = 44100;
export const FFT_SIZE = 1024; // 44100 / 30 , closest power of 2
export const DESYNC_AMOUNT = 0.001;
export default {
  components: {
    AudioWrapper
  },
	emits: ['frameUpdate'],
	props: ['script'],
	data() {
		return {
			isPlaying: false,
	  	audioDisplay: null,
	  	timeInterval: null,
	  	players: []
		}
	},
	mounted() {
		if (this.script) {
			this.script.eventTarget.addEventListener('setupDone', () => {
				this.reinitAudioAnalyzers();
			});
		}
	},
	methods: {
		reinitAudioAnalyzers() {
			this.players.map(idx=> this._getPlayer(idx))
			.forEach(player=>player.reinitAudioAnalyzers(this.script.getRenderParams()));
		},
		async fileChanged(file, idx) {
			if (idx===undefined) {
				idx = this.players.length;
			}
			let player = this._getPlayer(idx);
			if (!player) {
        this.players.push(idx);
        await this.$nextTick();
        this.fileChanged(file, idx);
        return;
			}
			player.fileChanged(file);
			if (idx>0) {
				player.setVolume(0);
				const currentTime = this._getPlayer(0).getCurrentTime();
				if (this.isPlaying) {
					player.seekTo(currentTime-DESYNC_AMOUNT);
					player.play();
				} else {
					player.seekTo(currentTime);
				}
			} else {
				player.setVolume(1);
				this._drawAudioTimeline(file);
				this.isPlaying = false;
			}
		},
		_getPlayer(idx) {
			const ref = this.$refs[`player${idx}`];
			if (ref) {
				return ref[0];
			}
			return ref;
		},
		toggleAudio() {
			if (!this._getPlayer(0)) {
				return false;
			}
			if (this.isPlaying) {
				this.audioPause();
			} else { 
				this.audioPlay();
			}
			return this.isPlaying;
		},
		audioPause() {
			if (!this._getPlayer(0)) {
				return false;
			}
			const paused = this._getPlayer(0).pause();
			if (paused) {
        for (let i=1; i<this.players.length; i++) {
          this._getPlayer(i).pause();
        }
				this.onPause();
				this.isPlaying = false;
			}
		},
		audioPlay() {
			if (!this._getPlayer(0)) {
				return false;
			}
			const played = this._getPlayer(0).play();
			if (played) {
        for (let i=1; i<this.players.length; i++) {
          this._getPlayer(i).play();
        }
				this.isPlaying = true;
			}
		},
		_drawAudioTimeline(file) {
			if (this.audioDisplay===null) {
				this._initAudioDisplay();	
			}
			this.audioDisplay.loadBlob(file);
		},

		_initAudioDisplay() {
			this.audioDisplay = new WaveSurferWrapper();
			this.audioDisplay.create({
        container: document.querySelector('#waveFormContainer'),
        waveColor: '#A8DBA8',
        progressColor: '#3B8686',
        height: 40
	    });
			this.audioDisplay.onSeek(this.doSeek);
		},
		doSeek(percentage) {
			for (let i=0; i<this.players.length; i++) {
				this._getPlayer(i).seekToPercentage(percentage);
			};
		},

		getAudioAnalysis() {
      return this.players.map(idx=> this._getPlayer(idx).getAudioAnalysis());
		},
		onPlay(index) {
			if (index>0) {
				return;
			}
			if (this.timeInterval) {
				clearInterval(this.timeInterval);
			}
			this.timeInterval = setInterval(() => {
				this.timeupdate();
			}, FRAME_RATE);
		},
		onPause() {
			this.isPlaying = false;
			if (this.timeInterval) {
				clearInterval(this.timeInterval);
				this.timeInterval = null;
			}
		},
		timeupdate() {
			const player = this._getPlayer(0);
			if (!player) {
				clearInterval(this.timeInterval);
				this.timeInterval = null;
				return;
			}
			this._seekTo(player.getPlayer().currentTime);
			this.$emit('frameUpdate', Math.round(player.getPlayer().currentTime*FRAME_RATE));
		},
		setFrame(frame) {
			this._seekTo(1/FRAME_RATE*frame);
		},
		_seekTo(time) {
			if (this.audioDisplay) {
				let location = this._map(time, 0, this._getPlayer(0).getPlayer().duration, 0, 1);
				if (isNaN(location)) {
					return;
				}
				if(location>1) {
					location = 1;
				}
				this.audioDisplay.seekTo(location);	
			}
		},
		_map(n, start1, stop1, start2, stop2) {
		  return ((n-start1)/(stop1-start1))*(stop2-start2)+start2;
		}
	}
}

</script>
<style>



</style>
<template>
		<AudioWrapper 
								v-for="(item, index) in players"
  							:key="index"
  							:ref="`player${index}`"
  							:title="`player${index}`"
                @play="onPlay(index)"
                @pause="onPause"
  />
  
</template>